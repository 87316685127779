import { BLOG_LABEL } from '@/legacy/utils/constants';

const pb_host = process.env.NEXT_PUBLIC_APP_ENV === 'dev' ? 'https://pickleballdev.net' : 'https://pickleball.com';
const pt_host = process.env.NEXT_PUBLIC_APP_ENV === 'dev' ? 'https://pickleballtournamentsdev.net' : 'https://pickleballtournaments.com';
const pl_host = process.env.NEXT_PUBLIC_APP_ENV === 'dev' ? 'https://pickleballleaguesdev.net' : 'https://pickleballleagues.com';

interface NavItem {
	id: string;
	children: string;
	target?: string;
	href: string;
}

export const NAVIGATION_ITEMS: NavItem[] = [
	{
		id: '1',
		children: 'News',
		href: `${pb_host}/news/all`
	},
	{
		id: '2',
		children: 'Tournaments',
		href: `${pt_host}`
	},
	{
		id: '3',
		children: 'Results',
		href: `${pb_host}/results`
	},
	{
		id: '4',
		children: 'Leagues',
		href: `${pl_host}`
	},
	{
		id: '5',
		children: 'Clubs',
		href: `${pb_host}/clubs`
	},
	{
		id: '6',
		children: 'Players',
		href: `${pb_host}/players`
	},
	{
		id: '7',
		children: 'Rankings',
		target: '_blank',
		href: `${pb_host}/rankings`
	},
	{
		id: '8',
		children: 'Tickets',
		target: '_blank',
		href: 'https://www.tixr.com/groups/ppa'
	},
	{
		id: '9',
		children: BLOG_LABEL,
		href: `${pb_host}/news/all`
	}
];
