'use client';

/* eslint-disable react-hooks/exhaustive-deps */
import type { ReactNode } from 'react';
import { createContext, useContext, useMemo } from 'react';

import { Permission } from '@/legacy/permissions/permission';

interface AuthContextType {
	roles: string[];
	hasPermission: (permissions: Permission | Permission[]) => boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children, roles }: { children: ReactNode; roles: string[] }) => {
	const hasPermission = (permissions: Permission | Permission[]): boolean => {
		if (roles.includes(Permission.SUPER_ADMIN)) {
			return true;
		}

		const checkPermissions = (perms: Permission[]) => perms.some((permission) => roles.includes(permission));
		return checkPermissions(Array.isArray(permissions) ? permissions : [permissions]);
	};

	const contextValue = useMemo(
		() => ({
			roles,
			hasPermission
		}),
		[roles]
	);

	return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
	const context = useContext(AuthContext);
	if (!context) {
		throw new Error('useAuth must be used within an AuthProvider');
	}

	return context;
};
